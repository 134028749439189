<template>
  <main class="bg-light">
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
    </div>
    <div
      v-if="pending"
      id="pending"
      class="d-flex flex-column justify-content-center align-items-center align-content-center"
    >
      <h1 class="text-danger">
        LOADING
      </h1>
      <b-spinner
        variant="danger"
        size="lg"
      />
    </div>
    <div
      v-else
    >

      <b-container>
        <b-row>
          <b-col class="p-4">
            <router-view/>
          </b-col>
        </b-row>
      </b-container>
      <b-container
        fluid="fluid"
        class="mt-2"
      >
        <b-row>
          <b-col class="p-1">
            <b-form v-on:submit.prevent="search">
              <div class="d-flex justify-content-end">
                <b-form-datepicker
                    id="start-date"
                    v-model="startDate"
                    class="mx-2"
                    size="sm"
                    locale="ko-KR"
                    style="max-width: 320px;"
                />
                <b-form-datepicker
                    id="end-date"
                    v-model="endDate"
                    class="mx-2"
                    size="sm"
                    locale="ko-KR"
                    style="max-width: 320px;"
                />
                <b-button type="submit" size="sm" squared>
                  <font-awesome-icon
                      icon="search"
                  />
                </b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table-simple id="list-table" bordered class="bg-white">
              <b-thead>
                <b-tr>
                  <b-th v-for="(col, idx) in columns" :key="`${col}-${idx}`">
                    {{ col }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tfoot>
                <b-tr>
                  <b-th :colspan="columns.length">
                    <div class="d-flex justify-content-between align-items-center">
                     <!-- <small class="font-weight-bold text-primary">
                          {{total}}개가 검색되었습니다.
                      </small>
                      <b-pagination-nav
                              :link-gen="linkGen"
                              :number-of-pages="totalPage"
                              use-router
                              first-number
                              last-number
                              size="sm"
                              align="right"
                      />-->
                    </div>
                  </b-th>
                </b-tr>
              </b-tfoot>
              <b-tbody>
                <b-tr
                  v-for="l of list"
                  :key="`row-date-${l.date}`"
                >
                  <b-td>
                    {{ l.date }}
                  </b-td>
                  <b-td>
                    {{ l.total }}
                  </b-td>
                  <b-td>
                    <progress-width
                        :per="l.coPer"
                        :color="colors['COMPLETE']"
                        :count="l.co"
                    />
                  </b-td>
                  <b-td>
                    <progress-width
                        :per="l.soPer"
                        :color="colors['TERMINATE']"
                        :count="l.so"
                    />
                  </b-td>
                  <b-td>
                    <progress-width
                        :per="l.qfPer"
                        :color="colors['QUOTA_FULL']"
                        :count="l.qf"
                    />
                  </b-td>
                  <b-td>
                    <progress-width
                        :per="l.dpPer"
                        :color="colors['JOIN']"
                        :count="l.dp"
                    />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="p-4">
            <chart
                v-if="chartData"
                :chart-data="chartData"
                :options="{responsive: true, maintainAspectRatio: false}"
                class="bg-white p-4"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </main>
</template>

<script>
import progressWidth from './progress'
import chart from './chart'
export default {
  name: "traffic",
  components: {
    progressWidth, chart
  },
  data: () => ({
    pending: false,
    columns: ['Date', 'Total(%)', 'CO(%)', 'SO(%)', 'QF(%)', 'Dp(%)'],
    page: 1,
    startDate: null,
    endDate: null,
    dates: [],
    colors: {},
    chartData: null
  }),
  created () {
    (async () => {
      await this.load()
    }) ()
  },
  computed: {
    list () {
      return this.dates.map(ans => {
        const { date, count } = ans
        const { TOTAL, JOIN, COMPLETE, QUOTA_FULL, TERMINATE } = count
        return {
          date,
          total: new Intl.NumberFormat().format(TOTAL),
          co: COMPLETE,
          coPer: COMPLETE ? (COMPLETE / TOTAL * 100) : 0,
          so: TERMINATE,
          soPer: TERMINATE ? (TERMINATE / TOTAL * 100) : 0,
          qf: QUOTA_FULL,
          qfPer: QUOTA_FULL ? (QUOTA_FULL / TOTAL * 100) : 0,
          dp: JOIN,
          dpPer: JOIN ? (JOIN / TOTAL * 100) : 0,
        }
      })
    }
  },
  methods: {
    async load (page) {
      this.pending = true
      if (page && !isNaN(+page)) this.page = +page
      try {
        const {data} = await this.axios({
          url: '/admin/ad/traffic',
          method: 'GET',
          params: {
            page: this.page,
            startDate: this.startDate,
            endDate: this.endDate
          }
        })
        console.log({
          page: this.page,
          startDate: this.startDate,
          endDate: this.endDate
        })
        const { result, dates, startDate, endDate, colors, chartData, error } = data
        if (result) {
          this.startDate = startDate
          this.endDate = endDate
          this.dates = dates
          this.colors = colors
          this.chartData = chartData
        } else {
          this.$bvToast.toast(`${error}`, {
            title: 'ERROR',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false
          })
        }
      } catch (e) {
        console.error(e)
      }
      this.pending = false
    },
    async search () {
      await this.load(1)
    }
  }
}
</script>

<style scoped>
  table td {
    font-size: 0.8rem;
  }
</style>
