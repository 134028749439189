<template>
  <div class="w-100 position-relative h-100 d-flex align-items-center bg-light">
    <div
        class="bg-transparent text-center mx-auto"
        :style="{'z-index': 999,  'color': this.width > 60 ? 'white' : 'black'}"
    >
      <div class="mx-auto">
        {{ new Intl.NumberFormat().format(count) }} ( {{ (per).toFixed(1) }} % )
      </div>
    </div>
    <div class="position-absolute" :style="style">&nbsp;</div>
  </div>
</template>

<script>
export default {
  name: "progress-td",
  props: {
    color: String,
    per: Number,
    count: Number
  },
  data: () => ({
    interval: null,
    width: 0,
    timeOut: 10
  }),
  mounted() {
    if (this.per) {
      this.timeOut = 10 / this.per * 50
      this.interval = setInterval(this.frame, this.timeOut)
    }
  },
  computed: {
    style () {
      return {height: '100%', width: `${this.width}%`, 'background-color': this.color, opacity: '0.5'}
    }
  },
  methods: {
    frame () {
      if (this.per > this.width) {
        this.width += 1
      } else {
        clearInterval(this.interval)
      }
    }
  }
}
</script>

<style scoped>

</style>
